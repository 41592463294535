import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08733310 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _abd261e6 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _19f555ea = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _593c47b1 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _003bfcf0 = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _36e5b698 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _9aaa1652 = () => interopDefault(import('../pages/reset-password-again/index.vue' /* webpackChunkName: "pages/reset-password-again/index" */))
const _ae51dbf8 = () => interopDefault(import('../pages/product/catalog/index.vue' /* webpackChunkName: "pages/product/catalog/index" */))
const _732bff42 = () => interopDefault(import('../pages/profile/company.vue' /* webpackChunkName: "pages/profile/company" */))
const _bc96328e = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _2f997ba8 = () => interopDefault(import('../pages/profile/loyalty.vue' /* webpackChunkName: "pages/profile/loyalty" */))
const _07cb3394 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _37a51385 = () => interopDefault(import('../pages/profile/promocodes.vue' /* webpackChunkName: "pages/profile/promocodes" */))
const _95d0dcc0 = () => interopDefault(import('../pages/profile/refunds/index.vue' /* webpackChunkName: "pages/profile/refunds/index" */))
const _36d1b8fd = () => interopDefault(import('../pages/profile/reviews.vue' /* webpackChunkName: "pages/profile/reviews" */))
const _095f146e = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _7eca2ae0 = () => interopDefault(import('../pages/room/catalog/index.vue' /* webpackChunkName: "pages/room/catalog/index" */))
const _291f7d49 = () => interopDefault(import('../pages/search/not-found.vue' /* webpackChunkName: "pages/search/not-found" */))
const _78d38ce8 = () => interopDefault(import('../pages/checkout/success/_order.vue' /* webpackChunkName: "pages/checkout/success/_order" */))
const _75ec7a3f = () => interopDefault(import('../pages/product/catalog/_slug/index.vue' /* webpackChunkName: "pages/product/catalog/_slug/index" */))
const _16a2cd44 = () => interopDefault(import('../pages/profile/orders/_order/index.vue' /* webpackChunkName: "pages/profile/orders/_order/index" */))
const _d1883536 = () => interopDefault(import('../pages/profile/refunds/_refund.vue' /* webpackChunkName: "pages/profile/refunds/_refund" */))
const _c1528c6a = () => interopDefault(import('../pages/room/catalog/_slug/index.vue' /* webpackChunkName: "pages/room/catalog/_slug/index" */))
const _113f7d9c = () => interopDefault(import('../pages/profile/orders/_order/refund.vue' /* webpackChunkName: "pages/profile/orders/_order/refund" */))
const _21c28c88 = () => interopDefault(import('../pages/blog/_slug/index.vue' /* webpackChunkName: "pages/blog/_slug/index" */))
const _e1b9a732 = () => interopDefault(import('../pages/catalog/_query.vue' /* webpackChunkName: "pages/catalog/_query" */))
const _38715a69 = () => interopDefault(import('../pages/contacts/_id/index.vue' /* webpackChunkName: "pages/contacts/_id/index" */))
const _32ac5336 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _2e4b687e = () => interopDefault(import('../pages/room/_slug/index.vue' /* webpackChunkName: "pages/room/_slug/index" */))
const _fab43050 = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _c0a54ed0 = () => interopDefault(import('../pages/set/_id/index.vue' /* webpackChunkName: "pages/set/_id/index" */))
const _fd4374cc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7fa7a452 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _08733310,
    name: "auth___pl"
  }, {
    path: "/blog",
    component: _abd261e6,
    name: "blog___pl"
  }, {
    path: "/cart",
    component: _19f555ea,
    name: "cart___pl"
  }, {
    path: "/checkout",
    component: _593c47b1,
    name: "checkout___pl"
  }, {
    path: "/compare",
    component: _003bfcf0,
    name: "compare___pl"
  }, {
    path: "/profile",
    component: _36e5b698,
    name: "profile___pl"
  }, {
    path: "/reset-password-again",
    component: _9aaa1652,
    name: "reset-password-again___pl"
  }, {
    path: "/product/catalog",
    component: _ae51dbf8,
    name: "product-catalog___pl"
  }, {
    path: "/profile/company",
    component: _732bff42,
    name: "profile-company___pl"
  }, {
    path: "/profile/favorites",
    component: _bc96328e,
    name: "profile-favorites___pl"
  }, {
    path: "/profile/loyalty",
    component: _2f997ba8,
    name: "profile-loyalty___pl"
  }, {
    path: "/profile/orders",
    component: _07cb3394,
    name: "profile-orders___pl"
  }, {
    path: "/profile/promocodes",
    component: _37a51385,
    name: "profile-promocodes___pl"
  }, {
    path: "/profile/refunds",
    component: _95d0dcc0,
    name: "profile-refunds___pl"
  }, {
    path: "/profile/reviews",
    component: _36d1b8fd,
    name: "profile-reviews___pl"
  }, {
    path: "/profile/tickets",
    component: _095f146e,
    name: "profile-tickets___pl"
  }, {
    path: "/room/catalog",
    component: _7eca2ae0,
    name: "room-catalog___pl"
  }, {
    path: "/search/not-found",
    component: _291f7d49,
    name: "search-not-found___pl"
  }, {
    path: "/checkout/success/:order?",
    component: _78d38ce8,
    name: "checkout-success-order___pl"
  }, {
    path: "/product/catalog/:slug",
    component: _75ec7a3f,
    name: "product-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order",
    component: _16a2cd44,
    name: "profile-orders-order___pl"
  }, {
    path: "/profile/refunds/:refund",
    component: _d1883536,
    name: "profile-refunds-refund___pl"
  }, {
    path: "/room/catalog/:slug",
    component: _c1528c6a,
    name: "room-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order/refund",
    component: _113f7d9c,
    name: "profile-orders-order-refund___pl"
  }, {
    path: "/blog/:slug",
    component: _21c28c88,
    name: "blog-slug___pl"
  }, {
    path: "/catalog/:query?",
    component: _e1b9a732,
    name: "catalog-query___pl"
  }, {
    path: "/contacts/:id",
    component: _38715a69,
    name: "contacts-id___pl"
  }, {
    path: "/product/:id",
    component: _32ac5336,
    name: "product-id___pl"
  }, {
    path: "/room/:slug",
    component: _2e4b687e,
    name: "room-slug___pl"
  }, {
    path: "/search/:query?",
    component: _fab43050,
    name: "search-query___pl"
  }, {
    path: "/set/:id",
    component: _c0a54ed0,
    name: "set-id___pl"
  }, {
    path: "/",
    component: _fd4374cc,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _7fa7a452,
    name: "slug___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
